import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";


// source
import PlaceIcon from "../assets/icons/place.svg";
import SeeRoute from "../assets/icons/btnPath.svg";
import WaitingImage from "../assets/img/temp.jpeg";


const Infos = ({ infos, searchWord }) => {

	useEffect(() => {
		const component = document.getElementById("scrollArea");
		component.scrollTo({ top: 0 })
	}, []);

	return (
		<div>
			<div
				style={{
					visibility: searchWord === "" ? "inherit" : "hidden",
					position: "absolute",
					bottom: 52,
					zIndex: 10,

					width: "calc(100vw - 32px)",
					left: 16,
					height: "88px",
					borderRadius: "12px",
					boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
					backgroundColor: "rgba(0, 0, 0, 0.1)",
				}}
			></div>

			<div
				// id="scrollArea"
				style={{
					visibility: searchWord === "" ? "inherit" : "hidden",
					position: "absolute",
					bottom: 52,
					zIndex: 10,
					width: "100vw",
					height: "88px",
					// overflowX: "scroll",
					userSelect: "none",
				}}
			>
				<InfoCardList
					id='scrollArea'
				>
					{infos.map((info, index) => (
						<li
							id={info.docId}
							key={info.docId}
							style={{
								// display: "inline-block",
								// width: "calc(100vw - 2j7px)",
								// height: "88px",
								// userSelect: "none",
								scrollSnapAlign: 'start',
								scrollSnapStop: 'always',
								display: 'inline-block',
								width: "100%",
								padding: "0 16px",
							}}
							onClick={(e) => {
								console.log('click!');
								const scrollWidth = window.innerWidth - 27;

								document.getElementById("scrollArea").scrollTo({
									left: scrollWidth * index, behavior: "smooth"
								});
							}}
						>
							<div style={{
								backgroundColor: "white",
								width: "calc(100vw - 32px)",
								height: "88px",
								borderRadius: "12px",
								position: "relative",
							}}>

								<img

									src={info.img && info.img !== "" ? info.img : WaitingImage}
									alt={info.name}
									style={{
										width: "88px",
										height: "88px",
										borderRadius: "12px 0 0 12px",
									}}
								/>
								<div
									style={{
										paddingLeft: "88px",
										position: "absolute",
										top: "25px",
										left: "12px",
										fontSize: "14px",
									}}
								>
									<div
										style={{
											width: "calc(100vw - 184px)",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										{info.name}
									</div>
								</div>

								<div
									style={{
										paddingLeft: "88px",
										position: "absolute",
										top: "48px",
										left: "12px",
										fontSize: "14px",
									}}
								>
									<img src={PlaceIcon} alt="" />
								</div>
								<div
									style={{
										paddingLeft: "88px",
										position: "absolute",
										top: "48px",
										left: "28px",
										fontSize: "14px",
									}}
								>
									<div
										style={{
											fontSize: "12px",
											color: "#6F7282",
											width: "calc(100vw - 200px)",

											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										{info.description}
									</div>
								</div>

								<Link to="../path">
									<button
										onClick={() => {
											sessionStorage.setItem("endPoint", JSON.stringify(info));
										}}
										style={{
											backgroundColor: "transparent",
											position: "absolute",
											top: "26px",
											right: "16px",
											border: "none",
											padding: 0,
										}}
									>
										<img src={SeeRoute} alt="" />
									</button>
								</Link>

							</div>

						</li>
					))}
				</InfoCardList>
			</div>
		</div >
	);
};

// const InfoCardList = styled.ul`
// 	overflow-x: scroll;
// // overflow-x: hidden;
// 	overflow-y: clip;
// 	height: 93px;
// 	gap: 10px;
//   padding: 0 16px;
//   margin: 0;
//   list-style: none;
//   white-space: nowrap;
//   -ms-overflow-style: none; // IE, Edge
//   scrollbar-width: none; // FireFox
//   ::-webkit-scrollbar {
//     display: none;
//   } // Chrome, Safari, Opera

//   -webkit-user-select:none; 
//   -moz-user-select:none; 
//   -ms-user-select:none; 
//   user-select:none;
// `;

const InfoCardList = styled.ul`
	-webkit-scroll-snap-type: x mandatory;
	scroll-snap-type: x mandatory;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	list-style: none;
	margin: 0;
	padding: 0px 29px;
	// padding: 0px;
	display: block;

	-ms-overflow-style: none; // IE, Edge
	scrollbar-width: none; // FireFox
	::-webkit-scrollbar {
	  display: none;
	} // Chrome, Safari, Opera

	-webkit-user-select:none; 
	-moz-user-select:none; 
	-ms-user-select:none; 
	user-select:none;

`;

Infos.propTypes = {
	infos: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchWord: PropTypes.string,
};

export default Infos;
