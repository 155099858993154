/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "../components/Header";
import Info from "../components/Info";
import Infos from "../components/Infos";
import Search from "../components/Search";

// sources - markers
import UnselectExhMarkerSrc from "../assets/icons/exhMarkerImageSrc_unselect.svg";
import UnselectExpMarkerSrc from "../assets/icons/expMarkerImageSrc_unselect.svg";
import UnselectConMarkerSrc from "../assets/icons/conMarkerImageSrc_unselect.svg";
import UnselectEtcMarkerSrc from "../assets/icons/etcMarkerImageSrc_unselect.svg";
import SelectExhMarkerSrc from "../assets/icons/exhMarkerImageSrc_select.svg";
import SelectExpMarkerSrc from "../assets/icons/expMarkerImageSrc_select.svg";
import SelectConMarkerSrc from "../assets/icons/conMarkerImageSrc_select.svg";
import SelectEtcMarkerSrc from "../assets/icons/etcMarkerImageSrc_select.svg";
import UnselectOverlapMarkerSrc from "../assets/icons/overlapMarkerImageSrc_unselect.svg";
import SelectOverlapMarkerSrc from "../assets/icons/overlapMarkerImageSrc_select.svg";

//source - category btns
import AllSelect from "../assets/buttons/s_all.svg";
import AllUnselect from "../assets/buttons/u_all.svg";
import ExhSelect from "../assets/buttons/s_exh.svg";
import ExhUnselect from "../assets/buttons/u_exh.svg";
import ExpSelect from "../assets/buttons/s_exp.svg";
import ExpUnselect from "../assets/buttons/u_exp.svg";
import ConSelect from "../assets/buttons/s_con.svg";
import ConUnselect from "../assets/buttons/u_con.svg";
import EtcSelect from "../assets/buttons/s_etc.svg";
import EtcUnselect from "../assets/buttons/u_etc.svg";

const { kakao } = window;

const unselectedMarkerSize = new kakao.maps.Size(32, 36);
const selectedMarkerSize = new kakao.maps.Size(40, 45);

const UnselectExhMarkerImage = new kakao.maps.MarkerImage(
  UnselectExhMarkerSrc,
  unselectedMarkerSize
);
const UnselectExpMarkerImage = new kakao.maps.MarkerImage(
  UnselectExpMarkerSrc,
  unselectedMarkerSize
);
const UnselectConMarkerImage = new kakao.maps.MarkerImage(
  UnselectConMarkerSrc,
  unselectedMarkerSize
);
const UnselectEtcMarkerImage = new kakao.maps.MarkerImage(
  UnselectEtcMarkerSrc,
  unselectedMarkerSize
);
const UnselectOverlapMarkerImage = new kakao.maps.MarkerImage(
  UnselectOverlapMarkerSrc,
  unselectedMarkerSize
);

const SelectExhMarkerImage = new kakao.maps.MarkerImage(
  SelectExhMarkerSrc,
  selectedMarkerSize
);
const SelectExpMarkerImage = new kakao.maps.MarkerImage(
  SelectExpMarkerSrc,
  selectedMarkerSize
);
const SelectConMarkerImage = new kakao.maps.MarkerImage(
  SelectConMarkerSrc,
  selectedMarkerSize
);
const SelectEtcMarkerImage = new kakao.maps.MarkerImage(
  SelectEtcMarkerSrc,
  selectedMarkerSize
);
const SelectOverlapMarkerImage = new kakao.maps.MarkerImage(
  SelectOverlapMarkerSrc,
  selectedMarkerSize
);

const Markers = ({
  exhPositions,
  expPositions,
  conPositions,
  etcPositions,
  places,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const [category, setCategory] = useState(0);
  const [placeInfo, setPlaceInfo] = useState(null);
  const [placeInfos, setPlaceInfos] = useState(null);
  const [mapT, setMapT] = useState(null);

  const [allInfoByCoords, setAllInfoByCoords] = useState([]);
  const [exhInfoByCoords, setExhInfoByCoords] = useState([]); // 좌표 기준 시설 목록. 예시) { coords: {}, marker:{}, infoList: [{}, {}, ...]}
  const [expInfoByCoords, setExpInfoByCoords] = useState([]); // 좌표 기준 시설 목록. 예시) { coords: {}, marker:{}, infoList: [{}, {}, ...]}
  const [conInfoByCoords, setConInfoByCoords] = useState([]); // 좌표 기준 시설 목록. 예시) { coords: {}, marker:{}, infoList: [{}, {}, ...]}
  const [etcInfoByCoords, setEtcInfoByCoords] = useState([]); // 좌표 기준 시설 목록. 예시) { coords: {}, marker:{}, infoList: [{}, {}, ...]}

  const [isAllDataReady, setIsAllDataReady] = useState(false);
  const [isExhDataReady, setIsExhDataReady] = useState(false); // 마커 생성 및 이벤트 리스너 등록까지 완료된 이후 true로 변경 > setMap 실행
  const [isExpDataReady, setIsExpDataReady] = useState(false); // 마커 생성 및 이벤트 리스너 등록까지 완료된 이후 true로 변경 > setMap 실행
  const [isConDataReady, setIsConDataReady] = useState(false); // 마커 생성 및 이벤트 리스너 등록까지 완료된 이후 true로 변경 > setMap 실행
  const [isEtcDataReady, setIsEtcDataReady] = useState(false); // 마커 생성 및 이벤트 리스너 등록까지 완료된 이후 true로 변경 > setMap 실행

  /**
   * 첫번째 실행. map 객체 초기화
   */
  useEffect(() => {
    const mapConatiner = document.getElementById("map");
    const mapOptions = {
      center: new kakao.maps.LatLng(34.7517113305368, 127.74934995939215),
      level: 5,
    };

    setMapT(new kakao.maps.Map(mapConatiner, mapOptions));
  }, []);

  /**
   * 두번째 실행. 좌표를 기준으로 데이터 구조 정리
   */

  const indexOfDuplicateCoords = (pos, infoByCoordsList) => {
    for (let coordsIdx in infoByCoordsList) {
      if (
        infoByCoordsList[coordsIdx].coords.La === pos.coords.La &&
        infoByCoordsList[coordsIdx].coords.Ma === pos.coords.Ma
      ) {
        return coordsIdx;
      }
    }
    return -1;
  };

  const changeDataStructure = (positions, setInfoByCoords) => {
    let infoByCoordsList = [];
    positions.forEach((pos) => {
      const coordsIdx = indexOfDuplicateCoords(pos, infoByCoordsList);
      if (coordsIdx >= 0) {
        infoByCoordsList[coordsIdx].infoList.push(pos);
      } else {
        infoByCoordsList.push({ coords: pos.coords, infoList: [pos], category: pos.category });
      }
    });
    setInfoByCoords(infoByCoordsList);
  };

  useEffect(() => {
    changeDataStructure(
      [...exhPositions, ...expPositions, ...conPositions, ...etcPositions],
      setAllInfoByCoords
    );
    changeDataStructure(exhPositions, setExhInfoByCoords);
    changeDataStructure(expPositions, setExpInfoByCoords);
    changeDataStructure(conPositions, setConInfoByCoords);
    changeDataStructure(etcPositions, setEtcInfoByCoords);
  }, [mapT]);

  /**
   * 세번째 실행. 좌표기준 데이터로 마커 생성
   */
  const createMarker = async (obj, image) => {
    const marker = new kakao.maps.Marker({ position: obj.coords, image });
    obj.marker = marker;
    return marker;
  };

  /* 전체 보기일 때 각 카테고리에 맞는 이미지로 초기화 하는 함수 */
  const initAllMarkers = () => {
    allInfoByCoords.forEach((obj) => {
      if (obj.infoList.length > 1)  // 좌표가 겹친다 -> Overlap 이미지
        createMarker(obj, UnselectOverlapMarkerImage);
      else { // 좌표가 겹치지 않는다 -> 기본 이미지
        if (obj.category === 1) createMarker(obj, UnselectExhMarkerImage);
        if (obj.category === 2) createMarker(obj, UnselectExpMarkerImage);
        if (obj.category === 3) createMarker(obj, UnselectConMarkerImage);
        if (obj.category === 4) createMarker(obj, UnselectEtcMarkerImage);
      }
    });
  };

  const initMarkers = (infoByCoords, unselectMarkerImage) => {
    infoByCoords.forEach((obj) => {
      (obj.infoList.length === 1
        ? createMarker(obj, unselectMarkerImage) // 좌표가 겹치지 않는다 -> 기본 이미지
        : createMarker(obj, UnselectOverlapMarkerImage)); // 좌표가 겹친다 -> Overlap 이미지
    });
  };

  /* 전체 보기일 때 지도 클릭 시 각 카테고리에 맞는 기본 이미지로 변경하는 함수 */
  const initAllImage = () => {
    allInfoByCoords.forEach((obj) => {
      if (obj.infoList.length > 1)  // 좌표가 겹친다 -> Overlap 이미지
        obj.marker.setImage(UnselectOverlapMarkerImage);
      else { // 좌표가 겹치지 않는다 -> 기본 이미지
        if (obj.category === 1) obj.marker.setImage(UnselectExhMarkerImage);
        if (obj.category === 2) obj.marker.setImage(UnselectExpMarkerImage);
        if (obj.category === 3) obj.marker.setImage(UnselectConMarkerImage);
        if (obj.category === 4) obj.marker.setImage(UnselectEtcMarkerImage);
      }
    });
  };

  const initImage = (infoByCoords, unselectMarkerImage) => {
    infoByCoords.forEach((obj) => {
      (obj.infoList.length === 1
        ? obj.marker.setImage(unselectMarkerImage) // 좌표가 겹치지 않는다 -> 기본 이미지
        : obj.marker.setImage(UnselectOverlapMarkerImage)); // 좌표가 겹친다 -> Overlap 이미지
    });
  };

  const setAlltoUnselectedImage = () => {
    initAllImage();
    initImage(exhInfoByCoords, UnselectExhMarkerImage);
    initImage(expInfoByCoords, UnselectExpMarkerImage);
    initImage(conInfoByCoords, UnselectConMarkerImage);
    initImage(etcInfoByCoords, UnselectEtcMarkerImage);
  };

  /* 전체 보기 시 마커의 클릭 이미지 정상화 */
  const addEachMarkerClickLisener = (obj, marker) => {
    kakao.maps.event.addListener(marker, "click", function () {
      setAlltoUnselectedImage();
      if (obj.infoList.length > 1) { // 좌표가 겹친다 -> Overlap 이미지
        marker.setImage(SelectOverlapMarkerImage);

        setPlaceInfos(() => obj.infoList.map((obj) => obj.info));
        setPlaceInfo(null);
      }
      else { // 좌표가 겹치지 않는다 -> 기본 이미지
        if (obj.category === 1) marker.setImage(SelectExhMarkerImage);
        else if (obj.category === 2) marker.setImage(SelectExpMarkerImage);
        else if (obj.category === 3) marker.setImage(SelectConMarkerImage);
        else if (obj.category === 4) marker.setImage(SelectEtcMarkerImage);

        setPlaceInfo(obj.infoList[0].info);
        setPlaceInfos(null);
      }
    });
  };

  const addMarkerClickListener = (obj, marker, clickImage, infoList) => {
    // 마커에 click 이벤트를 등록합니다
    kakao.maps.event.addListener(marker, "click", function () {
      setAlltoUnselectedImage();
      if (infoList.length > 1) {
        marker.setImage(SelectOverlapMarkerImage); // 좌표가 겹친다 -> Overlap 이미지

        setPlaceInfos(() => infoList.map((obj) => obj.info));
        setPlaceInfo(null);
      }
      else { // 좌표가 겹치지 않는다 -> 기본 이미지
        marker.setImage(clickImage);

        setPlaceInfo(infoList[0].info);
        setPlaceInfos(null);
      }
    });
  };

  /* 전체 마커 보기 경우의 process */
  const processAll = async () => {
    await initAllMarkers();
    await allInfoByCoords.forEach((obj) => {
      addEachMarkerClickLisener(obj, obj.marker);
    });
    await setIsAllDataReady(true);
  }

  const process = async (
    infoByCoords,
    selectMarkerImage,
    unselectMarkerImage,
    setIsDataReady
  ) => {
    await initMarkers(infoByCoords, unselectMarkerImage);
    await infoByCoords.forEach((obj) => {
      addMarkerClickListener(obj, obj.marker, selectMarkerImage, obj.infoList);
    });
    await setIsDataReady(true);
  };

  useEffect(() => {
    processAll();
  }, [allInfoByCoords]);

  useEffect(() => {
    process(
      exhInfoByCoords,
      SelectExhMarkerImage,
      UnselectExhMarkerImage,
      setIsExhDataReady
    );
  }, [exhInfoByCoords]);

  useEffect(() => {
    process(
      expInfoByCoords,
      SelectExpMarkerImage,
      UnselectExpMarkerImage,
      setIsExpDataReady
    );
  }, [expInfoByCoords]);

  useEffect(() => {
    process(
      conInfoByCoords,
      SelectConMarkerImage,
      UnselectConMarkerImage,
      setIsConDataReady
    );
  }, [conInfoByCoords]);

  useEffect(() => {
    process(
      etcInfoByCoords,
      SelectEtcMarkerImage,
      UnselectEtcMarkerImage,
      setIsEtcDataReady
    );
  }, [etcInfoByCoords]);

  /**
   * 네번째 실행. 생성된 마커와 맵을 연결
   */
  const toggleMarkers = (infoByCoords, map) => {
    infoByCoords.forEach((pos) => {
      pos.marker.setMap(map);
    });
  };

  useEffect(() => {
    toggleMarkers(allInfoByCoords, mapT);
  }, [isAllDataReady])

  useEffect(() => {
    toggleMarkers(exhInfoByCoords, null);
  }, [isExhDataReady]);

  useEffect(() => {
    toggleMarkers(expInfoByCoords, null);
  }, [isExpDataReady]);

  useEffect(() => {
    toggleMarkers(conInfoByCoords, null);
  }, [isConDataReady]);

  useEffect(() => {
    toggleMarkers(etcInfoByCoords, null);
  }, [isEtcDataReady]);

  const onSearch = async (e) => {
    await setSearchWord(e.target.value);
    await setPlaceInfo(null);
  };
  const onDeleteWord = () => setSearchWord("");

  return (
    <div style={{ overflow: 'hidden' }}>
      <Header
        id='map'
        view={"map"}
        searchWord={searchWord}
        onSearch={onSearch}
        onDeleteWord={async () => {
          await onDeleteWord();
        }}
      />

      {/* 카테고리 선택 버튼 */}
      <div
        style={{
          visibility: searchWord === "" ? "inherit" : "hidden",

          position: "absolute",
          top: 149,
          zIndex: 10,
          width: "100vw",
        }}
      >
        <Category>
          <li style={{ display: "inline-block" }}>
            <button
              onClick={() => {
                toggleMarkers(allInfoByCoords, mapT);
                toggleMarkers(exhInfoByCoords, null);
                toggleMarkers(expInfoByCoords, null);
                toggleMarkers(conInfoByCoords, null);
                toggleMarkers(etcInfoByCoords, null);
                setCategory(0);
                setPlaceInfo(null);
                setPlaceInfos(null);
              }}
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <img
                src={category === 0 ? AllSelect : AllUnselect}
                alt=""
                style={{ height: "36px" }}
              />
            </button>
          </li>
          <li style={{ display: "inline-block" }}>
            <button
              onClick={() => {
                toggleMarkers(allInfoByCoords, null);
                toggleMarkers(exhInfoByCoords, mapT);
                toggleMarkers(expInfoByCoords, null);
                toggleMarkers(conInfoByCoords, null);
                toggleMarkers(etcInfoByCoords, null);
                setCategory(1);
                setPlaceInfo(null);
                setPlaceInfos(null);
              }}
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <img
                src={category === 1 ? ExhSelect : ExhUnselect}
                alt=""
                style={{ height: "36px" }}
              />
            </button>
          </li>
          <li style={{ display: "inline-block" }}>
            <button
              onClick={() => {
                toggleMarkers(allInfoByCoords, null);
                toggleMarkers(exhInfoByCoords, null);
                toggleMarkers(expInfoByCoords, mapT);
                toggleMarkers(conInfoByCoords, null);
                toggleMarkers(etcInfoByCoords, null);
                setCategory(2);
                setPlaceInfo(null);
                setPlaceInfos(null);
              }}
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <img
                src={category === 2 ? ExpSelect : ExpUnselect}
                alt=""
                style={{ height: "36px" }}
              />
            </button>
          </li>
          <li style={{ display: "inline-block" }}>
            <button
              onClick={() => {
                toggleMarkers(allInfoByCoords, null);
                toggleMarkers(exhInfoByCoords, null);
                toggleMarkers(expInfoByCoords, null);
                toggleMarkers(conInfoByCoords, mapT);
                toggleMarkers(etcInfoByCoords, null);
                setCategory(3);
                setPlaceInfo(null);
                setPlaceInfos(null);
              }}
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <img
                src={category === 3 ? ConSelect : ConUnselect}
                alt=""
                style={{ height: "36px" }}
              />
            </button>
          </li>
          <li style={{ display: "inline-block" }}>
            <button
              onClick={() => {
                toggleMarkers(allInfoByCoords, null);
                toggleMarkers(exhInfoByCoords, null);
                toggleMarkers(expInfoByCoords, null);
                toggleMarkers(conInfoByCoords, null);
                toggleMarkers(etcInfoByCoords, mapT);
                setCategory(4);
                setPlaceInfo(null);
                setPlaceInfos(null);
              }}
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <img
                src={category === 4 ? EtcSelect : EtcUnselect}
                alt=""
                style={{ height: "36px" }}
              />
            </button>
          </li>
        </Category>
      </div>

      {/* 검색 결과 화면 */}
      {searchWord === "" ? (
        <></>
      ) : (
        <Search places={places} searchWord={searchWord} />
      )}

      {/* 지도 그리는 부분 */}
      <div
        onClick={(e) => {
          e.preventDefault();
          setAlltoUnselectedImage();
          setPlaceInfo(null);
          setPlaceInfos(null);
        }}
        id="map"
        style={{
          width: "100%",
          // position: "relative",
          position: "absolute",
          // height: "100vh",
          height: `${window.innerHeight - 138}px`,
          // height: "calc(100vh - 138px)",
          // height: {},
          top: 138,
          backgroundColor: "skyblue",
          visibility: searchWord === "" ? "inherit" : "hidden",
          overflowY: 'hidden',
        }}
      ></div>

      {placeInfo && <Info info={placeInfo} searchWord={searchWord} />}
      {placeInfos &&
        <Infos infos={placeInfos} searchWord={searchWord} />}
    </div>
  );
};

Markers.propTypes = {
  exhPositions: PropTypes.array,
  expPositions: PropTypes.array,
  conPositions: PropTypes.array,
  etcPositions: PropTypes.array,
  places: PropTypes.array,
  startPoint: PropTypes.object,
};

const Category = styled.ul`
  padding: 0 16px;
  margin: 0;
  list-style: none;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none; // IE, Edge
  scrollbar-width: none; // FireFox
  ::-webkit-scrollbar {
    display: none;
  } // Chrome, Safari, Opera
`;

export default Markers;
