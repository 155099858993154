import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SelectCategoryButton from "./SelectCategoryButton";

import WaitingImage from "../assets/img/temp.jpeg";

import SelectAll from "../assets/icons/select_all.svg";
import SelectExh from "../assets/icons/select_exhibitions.svg";
import SelectExp from "../assets/icons/select_experiences.svg";
import SelectCon from "../assets/icons/select_convenients.svg";
import SelectEtc from "../assets/icons/select_etcs.svg";
import UnselectAll from "../assets/icons/unselect_all.svg";
import UnselectExh from "../assets/icons/unselect_exhibitions.svg";
import UnselectExp from "../assets/icons/unselect_experiences.svg";
import UnselectCon from "../assets/icons/unselect_convenients.svg";
import UnselectEtc from "../assets/icons/unselect_etcs.svg";

import ExhBtn from "../assets/icons/btnExh.svg";
import ExpBtn from "../assets/icons/btnExp.svg";
import ConBtn from "../assets/icons/btnCon.svg";
import EtcBtn from "../assets/icons/btnEtc.svg";

const gridSide = "calc(50vw - 22px)";

const ListAll = ({
  isBannerVisible,
  exhibitions,
  experiences,
  convenients,
  etcs,
  showPath,
}) => {
  const [category, setCategory] = useState(0);
  const bannerHeight = isBannerVisible ? "82px" : "0";

  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   setIsLoading(false);
  // }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "146px",
        width: "100%",
      }}
    >
      {/* Category buttons */}
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "16px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <SelectCategoryButton
            selectIcon={SelectAll}
            unselectIcon={UnselectAll}
            categoryName="전체"
            onSelectCategory={() => setCategory(0)}
            isSelected={0 === category}
          />
          <SelectCategoryButton
            selectIcon={SelectExh}
            unselectIcon={UnselectExh}
            categoryName="전시시설"
            onSelectCategory={() => setCategory(1)}
            isSelected={1 === category}
          />
          <SelectCategoryButton
            selectIcon={SelectExp}
            unselectIcon={UnselectExp}
            categoryName="체험시설"
            onSelectCategory={() => setCategory(2)}
            isSelected={2 === category}
          />
          <SelectCategoryButton
            selectIcon={SelectCon}
            unselectIcon={UnselectCon}
            categoryName="편의시설"
            onSelectCategory={() => setCategory(3)}
            isSelected={3 === category}
          />
          <SelectCategoryButton
            selectIcon={SelectEtc}
            unselectIcon={UnselectEtc}
            categoryName="기타"
            onSelectCategory={() => setCategory(4)}
            isSelected={4 === category}
          />
        </div>
      </div>

      <div style={{ backgroundColor: "#EEEFF3", height: "1px" }} />

      {/* Places */}
      <div style={{ padding: "18px 16px" }}>
        <ul
          style={{
            gridTemplateColumns: "auto auto",
            display: "grid",
            // overflowY: "auto",
            listStyle: "none",
            margin: `auto auto ${bannerHeight} auto`,
            padding: "0",
            gap: "12px",
          }}
        >
          {(category === 0 || category === 1) &&
            exhibitions.map((place) => {
              return (
                <Link
                  to="path"
                  key={place.docId}
                  onClick={() => {
                    sessionStorage.setItem("endPoint", JSON.stringify(place));
                  }}
                >
                  <li
                    style={{
                      gridColumnEnd: "span 1",
                      gridRowEnd: "span 1",
                      display: "flex",
                      position: "relative",
                      width: gridSide,
                      height: gridSide,

                      borderRadius: "12px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <img
                      src={
                        place.img && place.img !== "" ? place.img : WaitingImage
                      }
                      alt={place.name}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "calc(100% - 55px)",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    >
                      <img src={ExhBtn} alt="전시" />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        backgroundColor: "white",
                        width: "calc(100% - 24px)",
                        padding: "12px",
                        borderRadius: "0 0 11px 11px",
                      }}
                    >
                      <div>{place.category}</div>
                      <div
                        style={{
                          color: "#282D4B",
                          fontSize: "13px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.name}
                      </div>
                      <div
                        style={{
                          color: "#9A9CAD",
                          fontSize: "12px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.description}
                      </div>
                    </div>
                  </li>
                </Link>
              );
            })}
          {(category === 0 || category === 2) &&
            experiences.map((place) => {
              return (
                <Link
                  to="path"
                  key={place.docId}
                  onClick={() => {
                    sessionStorage.setItem("endPoint", JSON.stringify(place));
                  }}
                >
                  <li
                    style={{
                      gridColumnEnd: "span 1",
                      gridRowEnd: "span 1",
                      display: "flex",
                      position: "relative",
                      width: gridSide,
                      height: gridSide,

                      borderRadius: "12px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <img
                      src={
                        place.img && place.img !== "" ? place.img : WaitingImage
                      }
                      alt={place.name}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "calc(100% - 55px)",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />


                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    >
                      <img src={ExpBtn} alt="체험" />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        backgroundColor: "white",
                        width: "calc(100% - 24px)",
                        padding: "12px",
                        borderRadius: "0 0 11px 11px",
                      }}
                    >
                      <div>{place.category}</div>
                      <div
                        style={{
                          color: "#282D4B",
                          fontSize: "13px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.name}
                      </div>
                      <div
                        style={{
                          color: "#9A9CAD",
                          fontSize: "12px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.description}
                      </div>
                    </div>
                  </li>
                </Link>
              );
            })}
          {(category === 0 || category === 3) &&
            convenients.map((place) => {
              return (
                <Link
                  to="path"
                  key={place.docId}
                  onClick={() => {
                    sessionStorage.setItem("endPoint", JSON.stringify(place));
                  }}
                >
                  <li
                    style={{
                      gridColumnEnd: "span 1",
                      gridRowEnd: "span 1",
                      display: "flex",
                      position: "relative",
                      width: gridSide,
                      height: gridSide,

                      borderRadius: "12px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <img
                      src={
                        place.img && place.img !== "" ? place.img : WaitingImage
                      }
                      alt={place.name}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "calc(100% - 55px)",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    >
                      <img src={ConBtn} alt="편의" />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        backgroundColor: "white",
                        width: "calc(100% - 24px)",
                        padding: "12px",
                        borderRadius: "0 0 11px 11px",
                      }}
                    >
                      <div>{place.category}</div>
                      <div
                        style={{
                          color: "#282D4B",
                          fontSize: "13px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.name}
                      </div>
                      <div
                        style={{
                          color: "#9A9CAD",
                          fontSize: "12px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.description}
                      </div>
                    </div>
                  </li>
                </Link>
              );
            })}
          {(category === 0 || category === 4) &&
            etcs.map((place) => {
              return (
                <Link
                  to="path"
                  key={place.docId}
                  onClick={() => {
                    sessionStorage.setItem("endPoint", JSON.stringify(place));
                  }}
                >
                  <li
                    style={{
                      gridColumnEnd: "span 1",
                      gridRowEnd: "span 1",
                      display: "flex",
                      position: "relative",
                      width: gridSide,
                      height: gridSide,

                      borderRadius: "12px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <img
                      src={
                        place.img && place.img !== "" ? place.img : WaitingImage
                      }
                      alt={place.name}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "calc(100% - 55px)",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />


                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    >
                      <img src={EtcBtn} alt="기타" />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        backgroundColor: "white",
                        width: "calc(100% - 24px)",
                        padding: "12px",
                        borderRadius: "0 0 11px 11px",
                      }}
                    >
                      <div>{place.category}</div>
                      <div
                        style={{
                          color: "#282D4B",
                          fontSize: "13px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.name}
                      </div>
                      <div
                        style={{
                          color: "#9A9CAD",
                          fontSize: "12px",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {place.description}
                      </div>
                    </div>
                  </li>
                </Link>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

ListAll.propTypes = {
  isBannerVisible: PropTypes.bool,
  exhibitions: PropTypes.array,
  experiences: PropTypes.array,
  convenients: PropTypes.array,
  etcs: PropTypes.array,
  showPath: PropTypes.func,
};

export default ListAll;
