import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// source
import PlaceIcon from "../assets/icons/place.svg";
import SeeRoute from "../assets/icons/btnPath.svg";
import WaitingImage from "../assets/img/temp.jpeg";


const Info = ({ info, searchWord }) => {
  return (
    <div
      style={{
        visibility: searchWord === "" ? "inherit" : "hidden",
        position: "absolute",
        bottom: 52,
        zIndex: 10,

        width: "calc(100vw - 32px)",
        left: 16,
        backgroundColor: "white",
        height: "88px",
        borderRadius: "12px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
      }}
    >
      <img
        src={info.img && info.img !== "" ? info.img : WaitingImage}
        alt={info.name}
        style={{
          width: "88px",
          height: "88px",
          borderRadius: "12px 0 0 12px",
        }}
      />
      <div
        style={{
          paddingLeft: "88px",
          position: "absolute",
          top: "25px",
          left: "12px",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            width: "calc(100vw - 184px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {info.name}
        </div>
      </div>
      <div
        style={{
          paddingLeft: "88px",
          position: "absolute",
          top: "48px",
          left: "12px",
          fontSize: "14px",
        }}
      >
        <img src={PlaceIcon} alt="" />
      </div>
      <div
        style={{
          paddingLeft: "88px",
          position: "absolute",
          top: "48px",
          left: "28px",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            fontSize: "12px",
            color: "#6F7282",
            width: "calc(100vw - 200px)",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {info.description}
        </div>
      </div>

      <Link to="../path">
        <button
          onClick={() => {
            sessionStorage.setItem("endPoint", JSON.stringify(info));
          }}
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: "26px",
            right: "16px",
            border: "none",
            padding: 0,
          }}
        >
          <img src={SeeRoute} alt="" />
        </button>
      </Link>
    </div>
  );
};

Info.propTypes = {
  info: PropTypes.object.isRequired,
  searchWord: PropTypes.string,
};

export default Info;
