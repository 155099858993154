import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// sources
import SearchIcon from "../assets/icons/search.svg";
import CancelIcon from "../assets/icons/cancel.svg";

const Header = ({ view, onSearch, searchWord, onDeleteWord }) => {
  return (
    <div
      style={{
        padding: "28px 16px 20px",
        boxShadow:
          view === "map" && searchWord === ""
            ? "0px 1px 8px rgba(0, 0, 0, 0.2)"
            : "none",
        position: "absolute",
        zIndex: 2,
        backgroundColor: "white",
        width: "calc(100vw - 32px)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textAlign: "left",

            fontSize: "24px",
            color: "#282D4B",
          }}
        >
          여수엑스포 길찾기
        </div>
        <div
          style={{
            marginLeft: "auto",
            width: "50px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {view === "list" && (
            <Link to="map">
              <div>
                <button
                  style={{
                    color: "#282D4B",
                    fontSize: "12px",

                    backgroundColor: "white",
                    border: "solid 1px #DFE0E7",
                    borderRadius: "8px",

                    width: "58px",
                    height: "25px",

                    padding: 0,
                  }}
                >
                  지도보기
                </button>
              </div>
            </Link>
          )}
          {view === "map" && (
            <Link to="../">
              <div>
                <button
                  style={{
                    color: "#282D4B",
                    fontSize: "12px",

                    backgroundColor: "white",
                    border: "solid 1px #DFE0E7",
                    borderRadius: "8px",

                    width: "58px",
                    height: "25px",

                    padding: 0,
                  }}
                >
                  목록보기
                </button>
              </div>
            </Link>
          )}
        </div>
      </div>

      {/* Show search page onClick */}
      <div
        style={{
          height: "44px",
          marginTop: "16px",
          backgroundColor: "#F3F4F8",
          borderRadius: "12px",
          color: "#9A9CAD",

          display: "table",
          width: "calc(100vw - 32px)",
        }}
      >
        <img
          src={SearchIcon}
          alt=""
          style={{ position: "absolute", margin: "14px 0 14px 16px" }}
          width="16px"
          height="16px"
        />
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            padding: 0,
          }}
        >
          <input
            style={{
              width: "calc(100% - 86px)",
              border: "none",

              fontSize: "14px",
              backgroundColor: "#F3F4F8",
              marginLeft: "41px",
            }}
            type="text"
            placeholder="시설, 장소를 검색해주세요"
            onChange={onSearch}
            value={searchWord}
          />
          {searchWord !== "" && (
            <button
              src={CancelIcon}
              alt=""
              style={{
                position: "absolute",
                padding: "0",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={onDeleteWord}
            >
              <img src={CancelIcon} alt="" width="20px" height="20px" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  view: PropTypes.string,
  onSearch: PropTypes.func,
  searchWord: PropTypes.string,
  onDeleteWord: PropTypes.func,
};

export default Header;
