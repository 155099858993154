import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import { firebaseApp, dbService } from "../firebase";


import Home from "../routes/Home";
import Markers from "../routes/Markers";
// import Search from "../routes/Search";
import Path from "../components/Path";

const { kakao } = window;

const App = () => {
  const startLocation = window.location.href.split("#")[1];
  const [startLocs, setStartLocs] = useState([]);

  const [places, setPlaces] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [convenients, setConvenients] = useState([]);
  const [etcs, setEtc] = useState([]);
  const [banner, setBanner] = useState("");

  const [exhPositions, setExhPositions] = useState([]);
  const [expPositions, setExpPositions] = useState([]);
  const [conPositions, setConPositions] = useState([]);
  const [etcPositions, setEtcPositions] = useState([]);

  const getPlaces = async () => {
    const dbStartLocs = await dbService.collection("startLoc").get();
    const dbExhibitions = await dbService.collection("exhibition").get();
    const dbExperiences = await dbService.collection("experience").get();
    const dbConvenients = await dbService.collection("convenient").get();
    const dbEtcs = await dbService.collection("etc").get();
    const dbBanner = await dbService.collection("banner").get();

    const initStart = () => {
      const startLocTmp = [];

      dbStartLocs.docs.forEach((location) => {
        if (location.exists) {
          const obj = {
            docId: location.id,
            collection: "startLoc",
            ...location.data(),
          };
          startLocTmp.push({
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
            ...obj,
          });
        } else console.log("No such document!");
      });
      setStartLocs(startLocTmp);
    };

    const initExh = () => {
      const exhTmp = [];
      const placeTmp = [];
      const exhPositionsTmp = [];

      dbExhibitions.docs.forEach((place) => {
        if (place.exists) {
          const obj = {
            docId: place.id,
            collection: "exhibition",
            ...place.data(),
          };
          exhTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          placeTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          exhPositionsTmp.push({
            coords: new kakao.maps.LatLng(
              obj.latlng.x,
              obj.latlng.y
            ).toCoords(),
            info: obj,
            category: 1,
          });
        } else console.log("No such document!");
      });
      setExhibitions(exhTmp);
      setPlaces((prev) => [...prev, ...placeTmp]);
      setExhPositions(exhPositionsTmp);
    };

    const initExp = () => {
      const expTmp = [];
      const placeTmp = [];
      const expPositionsTmp = [];
      dbExperiences.docs.forEach((place) => {
        if (place.exists) {
          const obj = {
            docId: place.id,
            collection: "experience",
            ...place.data(),
          };
          expTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          placeTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          expPositionsTmp.push({
            coords: new kakao.maps.LatLng(
              obj.latlng.x,
              obj.latlng.y
            ).toCoords(),
            info: obj,
            category: 2,
          });
        } else console.log("No such document!");
      });
      setExperiences(expTmp);
      setPlaces((prev) => [...prev, ...placeTmp]);
      setExpPositions(expPositionsTmp);
    };

    const initConv = () => {
      const conTmp = [];
      const placeTmp = [];
      const conPositionsTmp = [];

      dbConvenients.docs.forEach((place) => {
        if (place.exists) {
          const obj = {
            docId: place.id,
            collection: "convenient",
            ...place.data(),
          };
          conTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          placeTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          conPositionsTmp.push({
            coords: new kakao.maps.LatLng(
              obj.latlng.x,
              obj.latlng.y
            ).toCoords(),
            info: obj,
            category: 3,
          });
        } else console.log("No such document!");
      });
      setConvenients(conTmp);
      setPlaces((prev) => [...prev, ...placeTmp]);
      setConPositions(conPositionsTmp);
    };

    const initEtc = () => {
      const etcTmp = [];
      const placeTmp = [];
      const etcPositionsTmp = [];

      dbEtcs.docs.forEach((place) => {
        if (place.exists) {
          const obj = { docId: place.id, collection: "etc", ...place.data() };
          etcTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          placeTmp.push({
            ...obj,
            coords: {
              x: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getX(),
              y: new kakao.maps.LatLng(obj.latlng.x, obj.latlng.y)
                .toCoords()
                .getY(),
            },
          });
          etcPositionsTmp.push({
            coords: new kakao.maps.LatLng(
              obj.latlng.x,
              obj.latlng.y
            ).toCoords(),
            info: obj,
            category: 4
          });
        } else console.log("No such document!");
      });

      setEtc(etcTmp);
      setPlaces((prev) => [...prev, ...placeTmp]);
      setEtcPositions(etcPositionsTmp);
    };
    initStart();
    initExh();
    initExp();
    initConv();
    initEtc();

    await setBanner(dbBanner.docs[0].data());
  };

  const findStartInfo = async () => {
    const res = await startLocs.find((loc) => loc.i === startLocation);
    if (res !== undefined) {
      sessionStorage.setItem("startPoint", JSON.stringify(res));
    }
  };
  useEffect(() => {
    getPlaces();
  }, []);
  useEffect(() => {
    findStartInfo();
  }, [startLocs]);

  return (
    <div>
      <div>
        <style>{`
          body {
            margin: 0px;
            // padding: 0px;
          }
          body,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          span {
            font-family: "Spoqa Han Sans Neo", "sans-serif" !important;
          }
        `}</style>
      </div>

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                places={places}
                exhibitions={exhibitions}
                experiences={experiences}
                convenients={convenients}
                etcs={etcs}
                banner={banner.img}
              />
            }
          />
          <Route
            path="map"
            element={
              <Markers
                exhPositions={exhPositions}
                expPositions={expPositions}
                conPositions={conPositions}
                etcPositions={etcPositions}
                places={places}
              />
            }
          />
          <Route path="path" element={<Path />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
