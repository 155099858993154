import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// source
import ExhBtn from "../assets/icons/btnExh.svg";
import ExpBtn from "../assets/icons/btnExp.svg";
import ConBtn from "../assets/icons/btnCon.svg";
import EtcBtn from "../assets/icons/btnEtc.svg";
import PlaceIcon from "../assets/icons/place.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import SeeRoute from "../assets/icons/btnPath.svg";
import WaitingImg from "../assets/img/temp.jpeg";

const Search = ({ isBannerVisible, places, searchWord }) => {
  const [isPathVisible, setIsPathVisible] = useState(false);
  const bannerHeight = isBannerVisible ? "82px" : "0";

  const getCategoryBtn = (place) => {
    switch (place.collection) {
      case "exhibition":
        return ExhBtn;
      case "experience":
        return ExpBtn;
      case "convenient":
        return ConBtn;
      case "etc":
        return EtcBtn;
      default:
        return "";
    }
  };
  return (
    <div style={{ padding: "0 16px", position: "absolute" }}>
      <ul
        style={{
          padding: 0,
          margin: `146px auto ${bannerHeight} auto`,
        }}
      >
        {places.map(
          (place, index) =>
            place.name.includes(searchWord) && (
              <div key={place.docId}>
                {index > 0 && (
                  <div
                    style={{
                      backgroundColor: "#EEEFF3",
                      height: "1px",
                    }}
                  />
                )}

                <li
                  style={{
                    // borderRadius: "12px",
                    // boxShadow: "0 0 12px #F4F8FC",
                    listStyle: "none",
                    width: "calc(100vw - 32px)",
                    padding: "16px 0",
                    height: "88px",
                  }}
                >
                  <img
                    src={place.img ?? WaitingImg}
                    alt={place.name}
                    style={{
                      width: "88px",
                      height: "88px",
                      borderRadius: "12px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      display: "inline",
                      left: "16px",
                      margin: "8px",
                    }}
                  >
                    <img src={getCategoryBtn(place)} alt="" />
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                      color: "#282D4B",

                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",

                      display: "inline",
                      position: "absolute",
                      margin: "16px 0 0 12px",

                      width: "calc(100vw - 168px)",
                    }}
                  >
                    {place.name}
                  </div>

                  <div
                    style={{
                      display: "inline",
                      position: "absolute",
                      margin: "39px 0 0 12px",
                    }}
                  >
                    <img src={PlaceIcon} alt="" />
                  </div>
                  <div
                    style={{
                      display: "inline",
                      position: "absolute",
                      margin: "39px 0 0 26px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#6F7282",
                        verticalAlign: "top",
                        marginLeft: "3px",

                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                        width: "calc(100vw - 185px)",
                      }}
                    >
                      {place.description}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline",
                      position: "absolute",
                      margin: "57px 0 0 12px",
                    }}
                  >
                    <img src={PhoneIcon} alt="" />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#6F7282",
                        verticalAlign: "top",
                        marginLeft: "3px",
                      }}
                    >
                      {place.contact}
                    </span>
                  </div>

                  <Link to="../path">
                    <button
                      onClick={() => {
                        sessionStorage.setItem(
                          "endPoint",
                          JSON.stringify(place)
                        );
                      }}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",

                        display: "inline",
                        padding: 0,
                        position: "absolute",
                        right: "16px",
                        marginTop: "26px",
                      }}
                    >
                      <img src={SeeRoute} alt="" />
                    </button>
                  </Link>
                </li>
              </div>
            )
        )}
      </ul>
    </div>
  );
};

Search.propTypes = {
  places: PropTypes.array,
  searchWord: PropTypes.string.isRequired,
  isBannerVisible: PropTypes.bool,
};

export default Search;
