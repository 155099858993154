import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// source
import ArrowBack from "../assets/icons/arrow_back.svg";
import ArrowEast from "../assets/icons/east.svg";
import StartPoint from "../assets/icons/startPoint.svg";
import Elevator from "../assets/icons/elevator.svg";
import North from "../assets/icons/north.svg";
import KakaoIcon from "../assets/icons/kakaoIcon.svg";
import EndPoint from "../assets/icons/endPoint.svg";
import BtnSeeMap from "../assets/icons/btnSeeMap.svg";
import BtnSeePath from "../assets/icons/btnSeePath.svg";
import DeleteMiniMap from "../assets/buttons/clear_minimap.svg";

const Path = () => {
  const startPoint = JSON.parse(sessionStorage.getItem("startPoint"));
  const endPoint = JSON.parse(sessionStorage.getItem("endPoint"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [minimap, setMinimap] = useState("");
  const [height, setHeight] = useState("");
  const [description, setDescription] = useState("");
  const showMiniMap = (map, height, description) => {
    const num = height.substr(0, 3);
    const modalHeight = (+num / 360) * window.innerWidth;

    setMinimap(map);
    setHeight(modalHeight);
    setDescription(description);
    setIsMapVisible(true);
  };
  const showWalkPath = (e) => {
    e.preventDefault();
    // window.location.href = kakaoLink; // issue: 뒤로가기 시 데이터 날아감, 에러
    window.open(kakaoLink); // open a link in new tab
    // navigate(kakaoLink); // issue: 기존 도메인에 새 링크가 더해지는 형태여서 사용 불가
  };

  const startData = startPoint && startPoint.startInfo;
  const kakaoPath = [
    { icon: KakaoIcon, str: "카카오맵을 통해 도보 길찾기", btn: "map" },
  ];
  const endData = endPoint && endPoint.endInfo;
  const pathDetails = [
    {
      icon: StartPoint,
      str: `${startPoint.block} ${startPoint.name}`,
      btn: "",
    },
    ...startData,
    ...kakaoPath,
    ...endData,
    { icon: EndPoint, str: endPoint.name },
  ];

  // const kakaoLink = `https://m.map.kakao.com/actions/walkRoute?startLoc=${startPoint.name}&sxEnc=${startPoint.coords.xEnc}&syEnc=${startPoint.coords.yEnc}&endLoc=${endPoint.name}&exEnc=${endPoint.coords.xEnc}&eyEnc=${endPoint.coords.yEnc}&ids=P${startPoint.id},P${endPoint.id}&service=`;
  const kakaoLink = `https://map.kakao.com/?map_type=TYPE_MAP&target=walk&rt=${startPoint.coords.x},${startPoint.coords.y},${endPoint.coords.x},${endPoint.coords.y}&rt1=${startPoint.block === "" ? "" : startPoint.block + " "}${startPoint.name}&rt2=${endPoint.name}`;

  return (
    <div>
      <div
        style={{
          position: "fixed",
          display: "flex",

          zIndex: 1,
          top: 16,
          left: 16,
          inset: "auato 0 0 auto",
        }}
      >
        <Link to="../">
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
            }}
          >
            <img src={ArrowBack} alt="" />
          </button>
        </Link>
      </div>

      <div
        style={{
          // zIndex: 3,
          position: " absolute",
          top: 0,
          left: 0,
          // width: "100%",
          height: "100%",
          backgroundColor: "white",
          padding: "16px",
          color: "#282D4B",

          // overflow: "hidden",
        }}
      >
        <div
          style={{
            fontSize: "22px",
            lineHeight: "29px",
            padding: "44px 6px 20px",
          }}
        >
          <span style={{ color: "#477AE9" }}>{endPoint.name}</span>까지
          <br />
          길안내를 도와드릴게요.
        </div>
        <div
          style={{
            textAlign: "center",
            lineHeight: "18px",
            padding: "13px 0",
            width: "calc(100vw - 32px)",
            backgroundColor: "#F0F6FE",
            borderRadius: "8px",
            marginBottom: "6px",
            fontSize: "14px",
          }}
        >
          {`${startPoint.block} ${startPoint.name}`}
          <img
            src={ArrowEast}
            alt="to"
            style={{
              padding: "0 8px",
              position: "relative",
              top: "2px",
            }}
          />
          {endPoint.name}
        </div>
        <div style={{ minHeight: `${64 * pathDetails.length + 88}px` }}>
          {pathDetails.map((path, index) => {
            return (
              <div key={index}>
                {index > 0 && (
                  <div
                    style={{
                      backgroundColor: "#EEEFF3",
                      height: "1px",
                      width: "calc(100vw - 32px)",
                    }}
                  />
                )}
                <table
                  style={{
                    margin: "-2px 0",
                    fontSize: "14px",
                  }}
                >
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    {/* {path.btn !== "" && <col />} */}
                  </colgroup>
                  <tbody>
                    <tr>
                      <td style={{ padding: "13px 0", height: "32px" }}>
                        <img src={path.icon} alt="" />
                      </td>
                      {path.hasOwnProperty("btn") ? (
                        <td
                          style={{
                            padding: "0 0 0 12px",
                            width: "calc(100vw - 154px)",
                            height: "64px",
                            verticalAlign: "middle",
                          }}
                        >
                          {path.str}
                        </td>
                      ) : (
                        <td
                          style={{
                            padding: "0 0 0 12px",
                            width: "calc(100vw - 76px)",
                            height: "64px",
                            verticalAlign: "middle",
                          }}
                        >
                          {path.str}
                        </td>
                      )}

                      {path.btn === "start" && (
                        <td
                          style={{
                            position: "absolute",
                            right: 0,
                            padding: "20px 16px 0 0",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              showMiniMap(
                                startPoint.miniMap,
                                startPoint.miniMapHeight,
                                startPoint.description
                              );
                            }}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <img src={BtnSeeMap} alt="" />
                          </button>
                        </td>
                      )}
                      {path.btn === "end" && (
                        <td
                          style={{
                            position: "absolute",
                            right: 0,
                            padding: "20px 16px 0 0",
                          }}
                        >
                          <button
                            onClick={(e) =>
                              showMiniMap(
                                endPoint.miniMap,
                                endPoint.miniMapHeight,
                                endPoint.description
                              )
                            }
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <img src={BtnSeeMap} alt="" />
                          </button>
                        </td>
                      )}
                      {path.btn === "map" && (
                        <td
                          style={{
                            position: "absolute",
                            right: 0,
                            padding: "20px 16px 0 0",
                          }}
                        >
                          <button
                            onClick={showWalkPath}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <img src={BtnSeePath} alt="" />
                          </button>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          display: "flex",
          width: "100vw",

          zIndex: 1,
          bottom: 0,
          left: 0,
          inset: "auato 0 0 auto",

          color: "#423630",
          alignItems: "center",
          justifyContent: "center",

          height: "80px",
          backgroundColor: "white",
        }}
      >
        <button
          onClick={showWalkPath}
          style={{
            backgroundColor: "#1D4993",
            width: endPoint.hasOwnProperty("miniMap")
              ? "calc(50% - 20px)"
              : "calc(100% - 40px)",
            position: "absolute",
            left: "16px",
            height: "48px",
            bottom: "32px",
            border: "none",
            borderRadius: "8px",

            color: "white",
            fontSize: "14px",
          }}
        >
          카카오맵 도보 길찾기
        </button>
        {endPoint.hasOwnProperty("miniMap") && (
          <button
            onClick={(e) =>
              showMiniMap(
                endPoint.miniMap,
                endPoint.miniMapHeight,
                endPoint.description
              )
            }
            style={{
              backgroundColor: "#477AE9",
              width: "calc(50% - 20px)",
              position: "absolute",
              right: "16px",
              height: "48px",
              bottom: "32px",
              border: "none",
              borderRadius: "8px",

              color: "white",
              fontSize: "14px",
            }}
          >
            건물 상세 지도 보기
          </button>
        )}
      </div>

      {isMapVisible && (
        <>
          <div
            style={{
              position: "fixed",
              display: "flex",
              zIndex: 1,
              top: 0,
              left: 0,

              background: "black",
              opacity: "0.5",
              width: "100%",
              height: "100%",
            }}
          ></div>

          <div
            style={{
              position: "fixed",
              display: "flex",
              width: "100vw",
              height: height,
              maxHeight: "911px",
              borderRadius: "12px 12px 0 0",

              zIndex: 1,
              bottom: 0,
              // inset: "auto 0 0 auto",
              backgroundColor: "#ffffff",
              color: "#423630",
              alignItems: "center",
              justifyContent: "center",
              left: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 16,
                fontSize: "18px",
                color: "#282D4B",
              }}
            >
              {description}
            </div>
            <button
              onClick={(e) => setIsMapVisible(false)}
              style={{
                position: "absolute",
                top: 20,
                right: 16,
                backgroundColor: "transparent",
                border: "none",
                padding: "0",
              }}
            >
              <img src={DeleteMiniMap} alt="" />
            </button>

            <object
              id="minimap"
              type="image/svg+xml"
              data={minimap}
              style={{
                bottom: 40,
                position: "absolute",
                width: `${window.innerWidth - 32}px`,
                maxWidth: "700px",
              }}
            >
              <img src={minimap} alt="" />
            </object>
          </div>
        </>
      )}
    </div>
  );
};

export default Path;
