import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from 'styled-components';

// components
import Header from "../components/Header";
import ListAll from "../components/ListAll";
import Search from "../components/Search";

// sources
import DeleteBannerBtn from "../assets/icons/delete.svg";

import Loading from "../assets/img/loading.jpg";

const Home = ({
  places,
  exhibitions,
  experiences,
  convenients,
  etcs,
  banner,
}) => {
  const [isPathVisible, setIsPathVisible] = useState(false);
  const [visibility, setvisibility] = useState('visible');
  const fadeDuration = 3;

  // const [endPoint, setEndPoint] = useState({});
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const emptySpace = searchWord === "" ? "0" : "12px";
  const onSearch = (e) => {
    setSearchWord(e.target.value);
  };

  const onHideBanner = () => setIsBannerVisible(false);

  const showPath = () => {
    setIsPathVisible(true);
  };
  const hidePath = () => {
    setIsPathVisible(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    banner !== undefined && setIsLoading(false);
    banner !== undefined && sessionStorage.setItem("isLoading", JSON.stringify(false));
  }, [banner]);


  // if (!isLoading) {
  //   setTimeout(() => {
  //     setvisibility('hidden')
  //   }, fadeDuration)
  // }

  // return 
  // (isLoading) ? (<div style={{ width: "100%", height: "100%", backgroundClor: "white", zIndex: 10, backgroundColor: "white", position: "absolute" }}><img src={Loading} alt="Loading..." style={{ position: "absolute", top: "30vh", left: "20vw", width: "60vw" }} /></div>) : 
  return (
    <div>
      {/* {!isLoading && (
        <Fade>
          <div style={{
            width: "100%", height: "100%", backgroundClor: "white",
            zIndex: 10,
            backgroundColor: "white",
            position: "absolute",

            // opacity: "0",
            // visibility: "hidden",
            // transition: "opacity 1000ms , visibility 1000ms",
          }}>
            <img src={Loading} alt="Loading..." style={{ position: "absolute", top: "30vh", left: "20vw", width: "60vw" }} />
          </div>
        </Fade>
      )} */}

      <div
        style={{
          position: isPathVisible ? "fixed" : "inherit",
        }}
      >
        <Header
          view={"list"}
          onSearch={onSearch}
          searchWord={searchWord}
          onDeleteWord={() => setSearchWord("")}
        />

        <div
          style={{
            position: "absolute",
            top: "138px",
            width: "100%",
            height: "8px",
            backgroundColor: "#EEEFF3",
          }}
        />

        {searchWord === "" ? (
          <ListAll
            isBannerVisible={isBannerVisible}
            exhibitions={exhibitions}
            experiences={experiences}
            convenients={convenients}
            etcs={etcs}
            showPath={showPath}
          />
        ) : (
          <Search
            // startPoint={startPoint}
            isBannerVisible={isBannerVisible}
            places={places}
            searchWord={searchWord}
          />
        )}

        {/* Banner */}
        {isBannerVisible && (
          <div
            style={{
              position: "fixed",
              display: "flex",
              width: "102vw",
              overflowX: "hidden",

              // height: bannerHeight,
              zIndex: 2,
              bottom: 0,
              // inset: "auto 0 0 auto",
              // backgroundColor: "#ffeb34",
              color: "#423630",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={banner}
              alt="카카오 T 자동결제 신청하기"
              style={{
                width: "100%",
                maxHeight: "100px",
                backgroundColor: "#243073",
              }}
            />
            <button
              onClick={onHideBanner}
              style={{
                position: "absolute",
                top: "0.7rem",
                right: "1.1rem",
                backgroundColor: "transparent",
                border: "none",
                padding: "0",
              }}
            >
              <img src={DeleteBannerBtn} alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

Home.propTypes = {
  places: PropTypes.array,
  exhibitions: PropTypes.array,
  experiences: PropTypes.array,
  convenients: PropTypes.array,
  etcs: PropTypes.array,
  banner: PropTypes.string,
};

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const Fade = styled.div`
  display: inline-block;
  visibility: hidden;
  animation: ${fadeOut} 1s linear;
  transition: visibility 1s linear;
`;

export default Home;
