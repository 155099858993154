import PropTypes from "prop-types";

const SelectCategoryButton = ({
  selectIcon,
  unselectIcon,
  categoryName,
  onSelectCategory,
  isSelected,
}) => {
  return (
    <button
      onClick={onSelectCategory}
      style={{
        width: "20%",
        padding: "1%",
        fontSize: "12px",
        backgroundColor: "white",
        border: "none",
        color: "#282D4B",
      }}
    >
      {isSelected ? (
        <img
          src={selectIcon}
          alt=""
          style={{ width: "24px", height: "24px" }}
        />
      ) : (
        <img
          src={unselectIcon}
          alt=""
          tyle={{ width: "24px", height: "24px" }}
        />
      )}
      <p style={{ margin: "6px 0 10px 0" }}>{categoryName}</p>
    </button>
  );
};

SelectCategoryButton.propTypes = {
  selectIcon: PropTypes.string.isRequired,
  unselectIcon: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default SelectCategoryButton;
